import { useEffect } from 'react'
import { toast } from 'sonner'
import { createCheckoutSession } from '../server/actions'
import { useServerAction } from '@/shared/hooks/useServerAction'

export const useCheckout = (priceId: string) => {
	const createCheckoutSessionWithPriceId = createCheckoutSession.bind(null, {
		priceId,
	})

	const { isPending, handleServerAction, error } = useServerAction(
		createCheckoutSessionWithPriceId
	)

	useEffect(() => {
		if (error) {
			toast.error('Failed to create checkout session', {
				className: 'bg-black text-white',
			})
		}
	}, [error])

	return {
		isPending,
		handleServerAction,
		error,
	}
}
