import React from 'react'
import { Button, ButtonProps } from './Button'
import { cn } from '../utils/cn'
import { Spinner } from './Spinner'

const variantStyles = {
	white: {
		rootClass: '',
		backgroundClass: 'bg-white',
		textClass: 'text-black',
		shadowClass: 'bg-gray-400',
		disabledClass: 'bg-gray-200 text-gray-500',
	},
	primary: {
		rootClass: '',
		backgroundClass: 'bg-primary-500',
		textClass: 'text-white',
		shadowClass: 'bg-primary-800',
		disabledClass: 'bg-primary-300 text-gray-500',
	},
	black: {
		rootClass: '',
		backgroundClass: 'bg-gray-800',
		textClass: 'text-white',
		shadowClass: 'bg-black',
		disabledClass: 'bg-gray-600 text-gray-500',
	},
}

type TSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl'

const sizeStyles: Record<TSize, string> = {
	xs: 'px-2 py-1 text-xs min-w-[100px]',
	sm: 'px-4 py-2 text-sm min-w-[150px]',
	base: 'px-6 py-3 text-base min-w-[150px]',
	lg: 'px-8 py-4 text-lg min-w-[150px]',
	xl: 'px-12 py-6 text-xl min-w-[150px]',
}

export type CtaButtonProps =  Omit<ButtonProps, 'variant' | 'size'> & {
	variant?: keyof typeof variantStyles
	size?: TSize
	loading?: boolean
}

export const CtaButton = (
	props: CtaButtonProps
) => {
	const {
		variant = 'white',
		size = 'xl',
		children,
		className,
		disabled,
		loading,
		...buttonProps
	} = props

	const buttonContent = loading ? (
		<Spinner
			color={variantStyles[variant].textClass.replace('text-', '')}
			size={24}
			className="z-30"
		/>
	) : (
		<span
			className={cn(
				'capitalize z-30 font-bold',
				variantStyles[variant].textClass,
				disabled && variantStyles[variant].disabledClass
			)}
		>
			{children}
		</span>
	)

	return (
		<Button
			variant="unstyled"
			className={cn(
				`transform transition-transform duration-300 hover:scale-110 shadow-lg isolate bg-transparent relative max-w-fit min-w-[250px] flex items-center justify-center rounded-2xl`,
				sizeStyles[size],
				className,
				variantStyles[variant].rootClass,
				(disabled || loading) && 'hover:scale-100'
			)}
			disabled={disabled || loading}
			{...buttonProps}
		>
			<div
				className={cn(
					'absolute inset-0 -bottom-[6px] z-10 rounded-2xl',
					variantStyles[variant].shadowClass
				)}
			></div>
			<div
				className={cn(
					'absolute inset-0 z-20 rounded-2xl',
					variantStyles[variant].backgroundClass,
					disabled && variantStyles[variant].disabledClass
				)}
			></div>
			{buttonContent}
		</Button>
	)
}
