import { logger } from '@/shared/utils/logger'
import { useState, useCallback } from 'react'

export type TOnSuccess<ServerActionResult> = (
	result: ServerActionResult
) => void

// If ServerActionParams is undefined, the server action takes no parameters
// If ServerActionParams is defined, the server action must take parameters of that type
export type TServerAction<ServerActionParams, ServerActionResult> = [ServerActionParams] extends [never]
	? () => Promise<ServerActionResult>
	: (params: ServerActionParams) => Promise<ServerActionResult>

/**
 * Custom hook to handle server actions with loading state management.
 */
export function useServerAction<ServerActionParams, ServerActionResult>(
	serverAction: TServerAction<ServerActionParams, ServerActionResult>,
	onSuccess?: TOnSuccess<ServerActionResult>
) {
	const [isPending, setIsPending] = useState(false)
	const [error, setError] = useState<Error | null>(null)
	const [result, setResult] = useState<ServerActionResult | null>(null)

	const handleServerAction = useCallback(
		async (params?: ServerActionParams) => {
			setResult(null)
			setIsPending(true)
			try {
				// Type assertion needed because TypeScript can't infer the conditional type correctly
				const result = await (serverAction as any)(params)
				setResult(result)
				onSuccess?.(result)
				return result
			} catch (error) {
				logger('error', 'Failed to execute server action', { error })
				setError(error as Error)
				return undefined
			} finally {
				setIsPending(false)
			}
		},
		[serverAction, onSuccess]
	)

	return {
		isPending,
		error,
		handleServerAction,
		result,
	}
}
