'use client'

// Reference: https://nextjs.org/docs/app/building-your-application/data-fetching/server-actions-and-mutations#pending-states

import { useFormStatus } from 'react-dom'
import { Button, ButtonProps } from '../Button'
import { Spinner } from '../Spinner'
import { cn } from '@/shared/utils/cn'

// simple submit button to use within forms, for more flexiblity use `userServerAction` hook and `ActionButton`
export const SubmitButton = (props: ButtonProps) => {
	const { pending } = useFormStatus()
	const { children, disabled, className, ...restProps } = props

	return (
		<Button
			type="submit"
			disabled={disabled || pending}
			className={cn('sm:text-nowrap flex gap-2 items-center', className)}
			{...restProps}
		>
			{pending ? <Spinner /> : children}
		</Button>
	)
}
