import React from 'react'
import { SectionWrapper } from './SectionWrapper'
import { Star } from '@/shared/components/SquarePattern'

export const HighlightSectionWrapper = ({
	children,
}: {
	children: React.ReactNode
}) => {
	return (
		<SectionWrapper
			id="call-to-value"
			type="bordered"
			className="bg-white text-black shadow-highlight relative"
		>
			<div className="absolute inset-0 hidden lg:block pointer-events-none">
				<Star color="black" x="15%" y="40%" />
				<Star color="black" x="75%" y="20%" />
				<Star color="black" x="85%" y="45%" />
			</div>
			<div className="flex flex-col items-center justify-center">
				{children}
			</div>
		</SectionWrapper>
	)
}
