import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import { cn } from '../utils/cn'
import { Spinner, SpinnerProps } from './Spinner'
import { FcGoogle } from 'react-icons/fc'
import {
	FaFacebook,
	FaInstagram,
	FaTiktok,
	FaYoutube,
	FaXTwitter,
	FaSnapchat
} from 'react-icons/fa6'
import { Link } from './Link'

type ButtonVariant = 'filled' | 'outline' | 'subtle' | 'unstyled' | 'link' | 'ghost'
type ButtonSize = 'sm' | 'base' | 'lg'
type ButtonBrand =
	| 'google'
	| 'youtube'
	| 'instagram'
	| 'facebook'
	| 'tiktok'
	| 'x'
	| 'snapchat'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	children: React.ReactNode
	variant?: ButtonVariant
	loading?: boolean
	spinnerProps?: SpinnerProps
	size?: ButtonSize
	asLink?: boolean
	href?: string
	brand?: ButtonBrand
	target?: string
	rel?: string
}

const variantStyles: Record<
	ButtonVariant,
	{ buttonClass: string; spinnerProps: SpinnerProps }
> = {
	filled: {
		buttonClass: 'bg-primary-500 text-white hover:bg-primary-600',
		spinnerProps: { size: 24, color: 'border-gray-700' },
	},
	outline: {
		buttonClass:
			'bg-background text-primary-400 border border-primary-400 hover:bg-primary-900 hover:text-white',
		spinnerProps: { size: 24, color: 'border-primary-400' },
	},
	subtle: {
		buttonClass: 'bg-primary-800 text-primary-200 hover:bg-primary-700',
		spinnerProps: { size: 24, color: 'border-primary-200' },
	},
	unstyled: {
		buttonClass: '',
		spinnerProps: { color: 'border-current' },
	},
	link: {
		buttonClass:
			'text-white transition-colors duration-200 ease-in-out sm:text-nowrap hover:underline font-semibold',
		spinnerProps: { size: 24, color: 'border-primary-500' },
	},
	ghost: {
		buttonClass: 'bg-transparent text-white hover:bg-transparent',
		spinnerProps: { size: 24, color: 'border-primary-500' },
	},
}

const brandStyles: Record<
	ButtonBrand | 'default',
	{ buttonClass: string; spinnerProps: SpinnerProps }
> = {
	google: {
		buttonClass:
			'bg-white text-gray-800 hover:bg-gray-100 border border-gray-300 rounded-xl shadow-md ring-2 ring-gray-200 ring-offset-2 ring-offset-gray-300',
		spinnerProps: { size: 24, color: 'border-gray-700' },
	},
	youtube: {
		buttonClass:
			'bg-red-600 text-black hover:bg-red-700 border border-red-700 ring-2 ring-red-800 ring-offset-2 ring-offset-red-900 rounded-xl shadow-md',
		spinnerProps: { size: 24, color: 'border-black' },
	},
	instagram: {
		buttonClass:
			'instagram-gradient text-white hover:opacity-90 rounded-xl shadow-md ring-2 ring-[#833AB4] ring-offset-2 ring-offset-[#E1306C]',
		spinnerProps: { size: 24, color: 'border-white' },
	},
	facebook: {
		buttonClass:
			'bg-blue-500 text-white hover:bg-blue-600 border border-blue-700 ring-2 ring-blue-800 ring-offset-2 ring-offset-blue-900 rounded-xl shadow-md',
		spinnerProps: { size: 24, color: 'border-white' },
	},
	tiktok: {
		buttonClass:
			'bg-white text-black hover:bg-gray-100 border border-gray-300 ring-2 ring-gray-200 ring-offset-2 ring-offset-gray-300 rounded-xl shadow-md',
		spinnerProps: { size: 24, color: 'border-black' },
	},
	x: {
		buttonClass:
			'bg-black text-white hover:bg-gray-900 border border-gray-500 ring-2 ring-gray-400 ring-offset-2 ring-offset-gray-500 rounded-xl shadow-md',
		spinnerProps: { size: 24, color: 'border-white' },
	},
	snapchat: {
		buttonClass:
			'bg-[#FFFC00] text-black hover:bg-[#e3e009] border border-[#cccb68] ring-2 ring-[#b5b302] ring-offset-2 ring-offset-[#b5b302] rounded-xl shadow-md',
		spinnerProps: { size: 24, color: 'border-black' },
	},
	default: {
		buttonClass: '',
		spinnerProps: { color: 'border-current' },
	},
}

const sizeStyles: Record<ButtonSize, string> = {
	sm: 'text-sm px-2 py-1',
	base: 'text-base px-4 py-2',
	lg: 'text-lg px-6 py-3',
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			className,
			variant = 'filled',
			loading,
			spinnerProps,
			size = 'base',
			asLink = false,
			href,
			brand,
			...props
		},
		ref
	) => {
		const baseClasses =
			variant === 'unstyled' && !brand
				? ''
				: variant === 'link'
				? 'font-bold flex items-center justify-center'
				: 'font-bold rounded-md max-w-fit flex items-center justify-center min-w-[100px] sm:text-nowrap'

		const styles = brand
			? brandStyles[brand] || brandStyles.default
			: variantStyles[variant]

		const content = loading ? (
			<>
				<Spinner {...{ ...styles.spinnerProps, ...spinnerProps }} />
			</>
		) : (
			<>
				{brand === 'google' && <FcGoogle className="text-xl mr-2" />}
				{brand === 'youtube' && <FaYoutube className="text-xl mr-2" />}
				{brand === 'instagram' && <FaInstagram className="text-xl mr-2" />}
				{brand === 'facebook' && <FaFacebook className="text-xl mr-2" />}
				{brand === 'tiktok' && <FaTiktok className="text-xl mr-2" />}
				{brand === 'x' && <FaXTwitter className="text-xl min-h-[24px]" />}
				{brand === 'snapchat' && <FaSnapchat className="text-xl mr-2" />}
				{brand !== 'x' ? children : <span className="sr-only">{children}</span>}
			</>
		)

		if (asLink && href) {
			return (
				<Link
					href={href}
					className={cn(
						baseClasses,
						styles.buttonClass,
						sizeStyles[size],
						className
					)}
				>
					{content}
				</Link>
			)
		}

		return (
			<button
				ref={ref}
				className={cn(
					baseClasses,
					styles.buttonClass,
					sizeStyles[size],
					className
				)}
				{...props}
			>
				{content}
			</button>
		)
	}
)

Button.displayName = 'Button'
