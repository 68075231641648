'use client'
import dynamic from 'next/dynamic'
import { contactEmail } from '@/shared/constants/brand'
import { Disclosure, DisclosureButton } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa'
import { SectionWrapper } from '../SectionWrapper'
import { CtaButton } from '@/shared/components/CtaButton'
import { Link } from '@/shared/components/Link'
import { Suspense } from 'react'

// Lazy load framer-motion
const MotionWrapper = dynamic(
	() => import('./FAQMotion').then((mod) => mod.MotionWrapper),
	{
		ssr: false,
	}
)

const ContactCard = () => {
	return (
		<div className="flex flex-col items-center justify-center p-8 lg:p-16 border-2 border-white rounded-3xl text-balance dot-pattern h-[400px] lg:h-[600px]">
			<h3 className="text-2xl lg:text-4xl font-bold mb-4 sm:text-nowrap text-center">
				Got more questions?
			</h3>
			<p className="text-white mb-6 text-center">
				{
					"If you have questions not covered in the FAQ, or you would just say hello, I'd love to hear about you!"
				}
			</p>
			<CtaButton
				asLink
				size="lg"
				variant="white"
				href={`mailto:${contactEmail}`}
				className="flex items-center gap-2"
			>
				Send me an email
			</CtaButton>
			<Link href={`mailto:${contactEmail}`} className="text-sm text-white mt-6">
				{contactEmail}
			</Link>
		</div>
	)
}

const faqs = [
	{
		question: `Is this app secure?`,
		answer:
			'Your data and account information are encrypted. Even in the unlikely event that an evil hacker stole data from our database, they would not be able to decrypt it or do anything with it. So yes, it is secure.',
	},
	{
		question: 'Which social media platforms are supported?',
		answer:
			'Currently, we support YouTube only, with more platforms coming soon.',
	},
	{
		question: 'Can I customize the analytics I share?',
		answer:
			'Right now the analytics are fixed. I will add customization options in the future.',
	},
	{
		question: 'Can I control what data I share?',
		answer:
			'Right now you can only share what you see in your dashboard, I will add customization options depending on what the users request.',
	},
	{
		question: 'Do I need technical skills to use the app?',
		answer:
			"Not at all. I've designed the app to be intuitive and easy for everyone.",
	},
	{
		question: 'Who can access the data I share though my share links?',
		answer:
			'Anyone with the link can access the data, as it is public. If it is requested, I can implement the option of sharing only with specific accounts.',
	},
	{
		question:
			'Could a hacker hack the link I shared to have access to more of my analytics data?',
		answer:
			'No, that is impossible since the link you share is not directly accessing your channel analytics, it is a snapshot of your analytics securely saved and encrypted in our database.',
	},
]

export const FAQ = () => {
	return (
		<SectionWrapper
			id="faq"
			title="Got any questions? We've got answers!"
			sectionTag="FAQ"
		>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
				<dl className="mt-10 space-y-6 divide-y divide-white/50">
					{faqs.map((faq) => (
						<Disclosure as="div" key={faq.question} className="pt-6">
							{({ open }) => (
								<>
									<dt>
										<DisclosureButton className="flex w-full items-start justify-between text-left text-white">
											<span className="text-lg font-semibold leading-7">
												{faq.question}
											</span>
											<span className="ml-6 flex h-7 items-center">
												<FaChevronDown
													className={`size-6 text-gray-400 transform transition-transform duration-200 ${
														open ? 'rotate-180' : ''
													}`}
													aria-hidden="true"
												/>
											</span>
										</DisclosureButton>
									</dt>
									<Suspense fallback={<div className="h-0 overflow-hidden" />}>
										<MotionWrapper open={open}>
											<div className="mt-2 pr-12">
												<p className="text-lg leading-7 text-white">
													{faq.answer}
												</p>
											</div>
										</MotionWrapper>
									</Suspense>
								</>
							)}
						</Disclosure>
					))}
				</dl>
				<ContactCard />
			</div>
		</SectionWrapper>
	)
}
