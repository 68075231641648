import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { cn } from '../utils/cn'

type LinkProps = NextLinkProps &
	React.AnchorHTMLAttributes<HTMLAnchorElement> & {
		className?: string
		children: React.ReactNode
	}

export const Link = ({
	children,
	className,
	href,
	...linkProps
}: LinkProps) => {
	const isExternalLink =
		typeof href === 'string' &&
		(href.startsWith('http://') || href.startsWith('https://'))

	const externalProps = isExternalLink
		? {
				target: '_blank',
				rel: 'noopener noreferrer',
		  }
		: {}

	return (
		<NextLink
			href={href}
			{...externalProps}
			{...linkProps}
			className={cn(
				'text-white transition-colors duration-200 ease-in-out hover:underline font-semibold',
				className
			)}
		>
			{children}
		</NextLink>
	)
}
