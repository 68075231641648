'use client'
import { SubmitButton } from '@/shared/components/client/SubmitButton'
import { Input } from '@/shared/components/Input'
import { createLeadAction } from '../server/actions'
import { useFormState } from 'react-dom'
import { EMPTY_FORM_STATE } from '@/shared/forms-server-actions/utils'
import { useToastMessage } from '@/shared/forms-server-actions/client/useToastMessage'
import { useFormReset } from '@/shared/forms-server-actions/client/useFormReset'
import { ShinyBox } from '@/shared/components/ShinyBox'

export const LeadsWidget = (props: { buttonText?: string }) => {
	const [formState, action] = useFormState(createLeadAction, EMPTY_FORM_STATE)
	const noScriptFallback = useToastMessage(formState)
	const formRef = useFormReset(formState)

	return (
		<ShinyBox>
			<form
				className="w-full flex flex-col gap-3 items-stretch"
				action={action}
				ref={formRef}
			>
				<Input
					type="email"
					name="email"
					placeholder="lando@calrissian.com"
					required
					className="w-full"
				/>
				<SubmitButton className="w-full max-w-full">
					{props.buttonText || 'Join Waitlist'}
				</SubmitButton>

				{noScriptFallback}
			</form>
			<p className="text-center text-sm text-gray-300 mt-2">
				We will notify you when we launch
			</p>
		</ShinyBox>
	)
}
