import React from 'react'
import { cn } from '../utils/cn'

type SquarePatternProps = {
	squares?: [number, number][]
	stars?: [number, number][]
}

const SQUARE_SIZE = 130

export const Star = ({
	x,
	y,
	color = 'white',
}: {
	x: number|string
	y: number|string
	color?: string
}) => {
	const starClass = cn(color === 'black' ? 'star--black' : 'star')
	const top = typeof y === 'string' ? y : `${y}px`
	const left = typeof x === 'string' ? x : `${x}px`
	return (
		<div className={starClass} style={{ top, left }}>
			<div className={cn(starClass, 'vertical-line')}></div>
			<div className={cn(starClass, 'horizontal-line')}></div>
		</div>
	)
}

export const Square = ({ x, y }: { x: number; y: number }) => {
	return (
		<div className="square" style={{ top: `${y}px`, left: `${x}px` }}></div>
	)
}

export const SquarePattern = ({ squares, stars }: SquarePatternProps) => {
	return (
		<>
			<div className="landing-pattern absolute inset-0">
				{squares?.map(([x, y]) => (
					<Square
						key={`${x * SQUARE_SIZE}-${y * SQUARE_SIZE}`}
						x={x * SQUARE_SIZE}
						y={y * SQUARE_SIZE}
					/>
				))}
				{stars?.map(([x, y]) => (
					<Star
						key={`${x * SQUARE_SIZE}-${y * SQUARE_SIZE}`}
						x={x * SQUARE_SIZE}
						y={y * SQUARE_SIZE}
					/>
				))}
			</div>
		</>
	)
}
