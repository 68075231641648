'use client'

import { useCheckout } from './useCheckout'
import { CtaButton } from '@/shared/components/CtaButton'
import { CtaButtonProps } from '@/shared/components/CtaButton'

export type CheckoutButtonProps = Omit<
	CtaButtonProps,
	'onClick' | 'loading'
> & {
	priceId?: string | null
}

export const CheckoutButton = (props: CheckoutButtonProps) => {
	const { priceId, disabled, ...ctaButtonProps } = props
	const { handleServerAction, isPending } = useCheckout(priceId!)

	return (
		<>
			<CtaButton
				{...ctaButtonProps}
				loading={isPending}
				disabled={isPending || !priceId || disabled}
				onClick={handleServerAction}
			>
				{props.children}
			</CtaButton>
		</>
	)
}
