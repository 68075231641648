'use client'
import { CtaButton } from '@/shared/components/CtaButton'
import { useFormState, useFormStatus } from 'react-dom'
import { useToastMessage } from '@/shared/forms-server-actions/client/useToastMessage'
import { useFormReset } from '@/shared/forms-server-actions/client/useFormReset'
import { createLeadAction } from '../leads/server/actions'
import { EMPTY_FORM_STATE } from '@/shared/forms-server-actions/utils'
import { Input } from '@/shared/components/Input'
import { HighlightSectionWrapper } from './HighlightSectionWrapper'

export const LeadsSection = () => {
	const { pending } = useFormStatus()
	const [formState, action] = useFormState(createLeadAction, EMPTY_FORM_STATE)
	const noScriptFallback = useToastMessage(formState)
	const formRef = useFormReset(formState)

	return (
		<HighlightSectionWrapper>
			<h2 className="text-4xl font-bold">
				Start sharing your metrics as soon as we launch!
			</h2>
			<form
				action={action}
				ref={formRef}
				className="w-full flex flex-col items-center"
			>
				<Input
					type="email"
					name="email"
					placeholder="lando@calrissian.com"
					required
					className="w-full max-w-2xl mt-8 h-12 text-lg border-gray-500 rounded-lg shadow-sm bg-white focus:ring-gray-900 focus:border-gray-900 placeholder:text-gray-500"
					disabled={pending}
				/>
				<CtaButton
					type="submit"
					disabled={pending}
					loading={pending}
					variant="black"
					className="mt-4 text-center w-full max-w-2xl"
				>
					{'Join the waitlist'}
				</CtaButton>
				{noScriptFallback}
			</form>

			<p className="text-center mt-4">{'We will notify you when we launch'}</p>
		</HighlightSectionWrapper>
	)
}
